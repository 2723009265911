import React from 'react'
import {
  Container,
  Card
} from "reactstrap";

export default function DelAccAndroid() {
  return (
    <>
      <Container className='pt-5'>
        <Card className='warning bg-img'>

        </Card>
      </Container>
    </>
  )
}
