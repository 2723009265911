import React, { useState, useEffect, useContext } from 'react'
import '../../assets/css/login.css'
import {
    Container,
    Col,
    Row
} from "reactstrap";
import { Link } from "react-router-dom";
import AppleSignin from 'react-apple-signin-auth';
import FacebookLogin from 'react-facebook-login/dist/facebook-login-render-props';
import { GoogleLogin } from 'react-google-login';
import liff from '@line/liff'
import 'reactjs-line-login/dist/index.css';
import axios from 'axios';
import { UserContext } from '../../UserContext';
import { getBrowser } from 'getBrowser';
// import { getBrowser } from 'getBrowser';

export default function Body() {
    const { userDataContext, setUserDataContext } = useContext(UserContext)
    const [input, setInput] = useState({ username: '', password: '' })
    const [loginMsg, setLoginMsg] = useState('')
    const [idToken, setIdToken] = useState(null);

    function inputChange(event) {
        const { name, value } = event.target;
        setInput((prevInput) => {
            return {
                ...prevInput,
                [name]: value
            };
        });
    }

    const responseFacebook = (result) => {
        console.log('facebook result');
        console.log(result);
        console.log(result.email);
        console.log('facebook result end');
        axios.post(`${process.env.REACT_APP_API_CONTENT2}/loginsc`, {
            email: result.email,
            social_type: 'facebook',
            token: result.accessToken,
            device: getBrowser()
        }).then(result => {
            if (result.data.res_code == '0000') {
                localStorage.setItem('auth', result.data.res_data)
                localStorage.setItem('login', 'success')
                window.location.reload()
            }else{
                setLoginMsg('Error: ['+result.data.res_code+'] '+result.data.res_msg);
            }
        })
    }

    const responseGoogle = (res) => {
        axios.post(`${process.env.REACT_APP_API_CONTENT2}/loginsc`, {
            email: res.profileObj.email,
            social_type: 'google',
            token: res.accessToken,
            device: getBrowser()
        }).then(result => {
            if (result.data.res_code == '0000') {
                localStorage.setItem('auth', result.data.res_data)
                localStorage.setItem('login', 'success')
                window.location.reload()
            }else{
                setLoginMsg('Error: ['+result.data.res_code+'] '+result.data.res_msg);
            }
        })
    }
    // line+++++++++++++++++++++++++++++++++++++++
    const initLine = () => {
        liff.init({ liffId: '1656757415-pbVOdmWy' }, () => {
            if (liff.isLoggedIn()) {
                runApp();
            } else {
                liff.login();
            }
        }, err => console.error(err));
    }
    const runApp = () => {
        const idToken = liff.getIDToken();
        setIdToken(idToken)
        liff.getProfile().then(profile => {
            // console.log(profile)
            // console.log(liff.getDecodedIDToken().email)
            // setDisplayName(profile.displayName)
            // setUserId(profile.userId)
            axios.post(`${process.env.REACT_APP_API_CONTENT2}/loginsc`, {
                email: liff.getDecodedIDToken().email,
                social_type: 'line',
                token: profile.userId,
                device: getBrowser()
            }).then(result => {
                if (result.data.res_code == '0000') {
                    localStorage.setItem('auth', result.data.res_data)
                    localStorage.setItem('login', 'success')
                    window.location.reload()
                }else{
                    setLoginMsg('Error: ['+result.data.res_code+'] '+result.data.res_msg);
                }
            })
        }).catch(err => console.error(err))
    }
    // const logout = () => {
    //     liff.logout();
    //     window.location.reload();
    // }
    // line---------------------------------------
    function login(event) {
        event.preventDefault()
        axios.post(`${process.env.REACT_APP_API_CONTENT2}/login`, {
            "username": input.username,
            "password": input.password,
            "device": getBrowser()
        }).then(result => {
            if (result.data.res_code == '0000') {
                localStorage.setItem('auth', result.data.res_data)
                setUserDataContext(result.data.res_data)
                localStorage.setItem('login', 'success')
                setLoginMsg('')
                window.location.reload()
            } else {
                console.log('fail')
                setLoginMsg('บัญชี หรือ รหัสผ่าน ไม่ถูกต้อง หรือ '+result.data.res_msg);
                console.log(result.data.res_code);
                console.log(result.data.res_data);
                console.log(result.data.res_msg);
            }
        })
    }

    useEffect(() => {
        if (localStorage.getItem('auth')) {
            axios.post(`${process.env.REACT_APP_API_CONTENT2}/userinfo`, 'data', {
                headers: {
                    Authorization: `Bearer ${window.localStorage.getItem('auth')}`
                },
            }).then(result=>'')
        }
        // initLine()
        liff.init({ liffId: '1656757415-pbVOdmWy' }, () => {
            if (liff.isLoggedIn()) {
                runApp();
            }
        }, err => console.error(err));
    }, [])

    const FormHeader = props => (
        <h2 id="headerTitle" style={{ color: 'white' }}>{props.title}</h2>
    );

    const FormButton = props => (
        <div id="button" className="login-row">
            <button type='submit' className='login-btn' >{props.title}</button>
        </div>
    );

    const OtherMethods = () => (
        <div id="alternativeLogin">
            <Link to='/login/register'>
                <p style={{ color: 'white' }}>สมัครสมาชิก</p>
            </Link>
            <p style={{ color: 'gray' }}>หรือ</p>
            <p style={{ color: 'white' }}>เข้าสู่ระบบผ่าน:</p>
            <div id="iconGroup">
            {/* 672428657996299 */}
                <FacebookLogin
                    appId="552058292546028"
                    fields="name,email"
                    isMobile={true}
                    callback={responseFacebook}
                    render={renderProps => (
                        <button
                            className="btn-just-icon mr-1 pr-3 pl-3 facebook-btn"
                            style={{ color: 'white', 'background-color': '#3b5998', 'border-color': '#3b5998', 'border': 'none', 'border-radius': '5px' }}
                            onClick={renderProps.onClick}
                        >
                            <i className="fa fa-facebook" />
                        </button>
                    )}
                />
                <GoogleLogin
                    autoLoad={false}
                    clientId="79835560689-69vroq9vk7f1k5o3off2siqoh9hlpfnv.apps.googleusercontent.com"
                    render={renderProps => (
                        <button className="btn-just-icon mr-1 pr-3 pl-3" color="google"
                            style={{ color: 'white', 'background-color': 'rgb(221,75,57)', 'border-color': 'rgb(221,75,57)', 'border': 'none', 'border-radius': '5px' }}
                            onClick={renderProps.onClick} disabled={renderProps.disabled}
                        >
                            <i className="fa fa-google" />
                        </button>
                    )}
                    buttonText="Login"
                    onSuccess={responseGoogle}
                    onFailure={responseGoogle}
                    cookiePolicy={'single_host_origin'}
                />
                <button className='line-login-btn' onClick={initLine}>
                    <i className="fab fa-line"></i>
                </button>
                <AppleSignin
                    /** Auth options passed to AppleID.auth.init() */
                    authOptions={{
                        /** Client ID - eg: 'com.example.com' */
                        clientId: 'com.mvhubplus.logindev',
                        /** Requested scopes, seperated by spaces - eg: 'email name' */
                        scope: 'email name',
                        /** Apple's redirectURI - must be one of the URIs you added to the serviceID - the undocumented trick in apple docs is that you should call auth from a page that is listed as a redirectURI, localhost fails */
                        redirectURI: 'https://service.mvhub.com/applelogin',
                        /** State string that is returned with the apple response */
                        state: 'state',
                        /** Nonce */
                        nonce: 'nonce',
                        /** Uses popup auth instead of redirection */
                    }} // REQUIRED
                    noDefaultStyle={false}
                    /** Allows to change the button's children, eg: for changing the button text */
                    buttonExtraChildren="Continue with Apple"
                    /** Extra controlling props */
                    /** Called upon signin success in case authOptions.usePopup = true -- which means auth is handled client side */
                    onSuccess={(response) =>''} // default = undefined
                    /** Called upon signin error */
                    onError={(error) => console.error(error)} // default = undefined
                    /** Skips loading the apple script if true */
                    skipScript={false} // default = undefined
                    /** Apple image props */
                    iconProp={{ style: { marginTop: '10px' } }} // default = undefined
                    /** render function - called with all props - can be used to fully customize the UI by rendering your own component  */
                    render={(props) => <button {...props} className='btn-just-icon mr-1 pr-3 pl-3 apple-btn'><i className="fa fa-apple"></i></button>}
                />
            </div>
        </div >
    );

    return (
        <div className='bg-img'>
            <br />
            <Container className='pb-5'>
                <Row>
                    <Col className='mt-5 mb-5'>
                        <div id="loginform">
                            <FormHeader title="Log in" />
                            <div class="login-row" style={{ color: 'red' }}>
                                {loginMsg}
                            </div>
                            <form onSubmit={login}>
                                <div class="login-row">
                                    <label style={{ color: 'white' }}>บัญชีผู้ใช้</label>
                                    <input id='username' name='username' style={{ color: 'black' }} type='text' placeholder='username' onChange={(event) => inputChange(event)} />
                                </div>
                                <div class="login-row">
                                    <label style={{ color: 'white' }}>รหัสผ่าน</label>
                                    <input id='password' name='password' style={{ color: 'black' }} type='password' placeholder='password' onChange={(event) => inputChange(event)} />
                                </div>
                                <FormButton title="เข้าสู่ระบบ" />
                            </form>
                            <Link to='/login/forgetpass'>
                                <div style={{ color: 'white', textAlign: 'center' }}>
                                    ลืมรหัสผ่าน?
                                </div>
                            </Link>
                            <OtherMethods />
                        </div>
                    </Col>
                </Row>
            </Container>
        </div>
    )
}
