import React from 'react'

export default function Result() {

    return (
        <div className='bg-img'>
            <h1 className='pt-5 text-yellow text-center'>ชำระเงินเรียบร้อย</h1>
        </div>
    )
}
