import React from 'react'
import {
  Container,
  Card
} from "reactstrap";

export default function CancelSubEN() {
  return (
    <>
      <Container className='pt-5'>
        <Card className='warning bg-img'>
        <h1>Canceling your membership plan</h1><br></br><br></br>
(1) Cancellation You may cancel your MVhub membership at any time. You will continue to have access to the MVhub Services until the end of your monthly billing, to the extent permitted by applicable law. Service fees will not be refunded, and MVhub will not provide refunds or credits for uncompleted months of membership or unwatched MVhub content. To cancel, go to your 'Account' page and follow the instructions to cancel. If you cancel your membership Your account will be automatically closed at the end of your current billing period. You can check the date your account will be closed by clicking on 'Billing Details' on the 'Account' page if you signed up with MVhub using your existing account with a third party. Payment and wish to cancel your MVhub membership, you may need to do so through that third party, for example by going to your account connected to that third party and turning off auto-renewal. or cancel your MVhub subscription through that third party. You can also view billing information about your MVhub subscription by going to your account with that third party.<br></br>
(2) How do I cancel?<br></br>
(2.1) If you pay for your membership plan by direct payment. Please follow the cancellation instructions listed on the page. “My Information” at least twenty-four (24) hours before the next applicable billing date. You can also check the expiration date of your membership plan on the page. “My Profile”<br></br>
(2.2) If you are paying for your Subscription through the App Store or Google Play, please do so via your chosen payment gateway at least twenty-four (24) hours before the billing date. will come into effect next time. For example, you can turn off automatic renewal or cancel your membership under your account with the payment gateway. For more details, App Store and Google Play users can visit the App Store at https://support.apple.com/th-th/HT202039 and Google Play at https://support.google.com/googleplay/answer/7018481?co=GENIE.Platform%3DAndroid&hl=th&oco=1

        </Card>
      </Container>
    </>
  )
}
