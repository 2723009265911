import axios from 'axios'
import React, { useEffect, useState, useRef } from 'react'
// import { useParams } from 'react-router-dom'
// import { Helmet } from "react-helmet";
import $ from 'jquery';
// import {
//     Button,
//     Col,
//     Row
// } from "reactstrap";
// import { getOS } from 'getOs';

export default function PayKrungsri() {
    // const [value, setValue] = useState([])
    // const [vals, setVals] = useState([])
    const [os, setOs] = useState()
    // const [IsLoading, setIsLoading] = useState(false)

    const urlSearchParams = new URLSearchParams(window.location.search);
    const params = Object.fromEntries(urlSearchParams.entries());

    useEffect(() => {
        var isMobile = (/iphone|ipad|ipod|android|ie|blackberry|fennec/).test
            (navigator.userAgent.toLowerCase());

        var isAndroid = (/android/).test
            (navigator.userAgent.toLowerCase());
        console.log("isMobile : ", isMobile);
        console.log("isAndroid : ", isAndroid);
        if (isAndroid) {
            setOs("ANDROID")
        } else {
            setOs("IOS")
        }
    }, [])

    $(document).ready(function () {
        $('form').submit();
    });
    return (
        <div className='bg-img'>
            <div>Loading...</div>
            <form method="post" action="https://api.gbprimepay.com/v1/mobileBanking/kma">
                <div className="container-fluid text-left" id="example1">
                    <label for="phone"></label>
                    <input id="mobileNo" className="inputborder" type="hidden" name="mobileNo" placeholder="089-999-9999" pattern="[0-9]{10}" maxlength="10" required="true" value={params.value1} />
                    <input id="id" className="inputborder" type="hidden" name="id" value={params.value2} />
                    <input id="osPlatform" className="inputborder" type="hidden" name="osPlatform" value={os} />
                    &nbsp;
                    <br /><br />
                    <input type="hidden" name="noncart" value={params.value3} />
                </div>
            </form>
        </div>
    )
}
