import React, { useState, useEffect, useRef, useContext } from "react";
import axios from 'axios'
import pictureA from "../../assets/img/header/main-logo.png"
// reactstrap components
import {
  DropdownMenu,
  DropdownItem,
  UncontrolledDropdown,
  DropdownToggle,
  Form,
  NavbarBrand,
  Navbar,
  NavItem,
  NavLink,
  Nav,
  Collapse,
  NavbarToggler,
} from "reactstrap";

// core components

function SectionNavigation() {
  const navRef = useRef()
  const [bodyClick, setBodyClick] = React.useState(false);
  const [isOpen, setIsOpen] = React.useState(false);
  const [searchInput, setSearchInput] = useState()
  const [login_status, setLogin_Status] = useState([])

  const searchSubmit = (e) => {
    e.preventDefault()
    window.location.href = `/search/${searchInput}`
    axios.post(`${process.env.REACT_APP_API}/mvhub/search`, {
      search: searchInput
    }, {
      headers: {
        Authorization: `Bearer ${window.localStorage.getItem('auth')}`
      },
    }).then(() => {
    })
  }
  useEffect(() => {
    if (window.location.hostname.indexOf('www') === 0) {
      window.location = window.location.href.replace('www.','')
    }
    navRef.current = window.location.toString().split('/').pop()
    localStorage.getItem('auth') ? setLogin_Status(['#fff200', '/login/account']) : setLogin_Status(['white', '/login'])
  }, [])
  return (
    <>
      {bodyClick ? (
        <div
          id="bodyClick"
          onClick={() => {
            document.documentElement.classList.toggle("nav-open");
            setBodyClick(false);
          }}
        />
      ) : null}
      <div>
        <div id="navbar-full navbar-sm sticky-top" >
          <Navbar className='navbar-sm sticky-top' color="dark" dark expand="lg">
            <NavbarBrand href="/">
              <img className='header-img ml-5' src={pictureA} alt='mvtv' />
            </NavbarBrand>
            <NavbarToggler onClick={() => { setIsOpen(!isOpen) }} />
            <Collapse className='collapse-btn' isOpen={isOpen} navbar>
              <Nav className="mr-auto" navbar>
                <NavItem active={Boolean(navRef.current == '')}>
                  <NavLink
                    className='header-text'
                    href="/"
                  >
                    หน้าแรก <span className="sr-only">(current)</span>
                  </NavLink>
                </NavItem>
                <NavItem active={Boolean(navRef.current == 'tv')}>
                  <NavLink
                    className='header-text'
                    href="/tv"
                  >
                    ช่องทีวี
                  </NavLink>
                </NavItem>
                <NavItem active={Boolean(navRef.current == 'comingsoon')}>
                  <NavLink
                    className='header-text'
                    href="/comingsoon"
                  >
                    เร็วๆนี้
                  </NavLink>
                </NavItem>
                <NavItem active={Boolean(navRef.current == 'hit')}>
                  <NavLink
                    className='header-text'
                    href="/hit"
                  >
                    ฮิตติดอันดับ
                  </NavLink>
                </NavItem>
                <NavItem active={Boolean(navRef.current == 'package')}>
                  <NavLink
                    className='header-text'
                    href="/package"
                  >
                    แพ็คเกจ
                  </NavLink>
                </NavItem>
                {isOpen == true &&
                  <>
                    {localStorage.getItem('auth') &&
                      <NavItem>
                        <NavLink
                          className='header-text'
                          href={login_status[1]}
                        >
                          ข้อมูลผู้ใช้
                        </NavLink>
                      </NavItem>
                    }
                    {!localStorage.getItem('auth') &&
                      <NavItem>
                        <NavLink
                          className='header-text'
                          href={login_status[1]}
                        >
                          เข้าสู่ระบบ
                        </NavLink>
                      </NavItem>
                    }
                    <UncontrolledDropdown >
                      <DropdownToggle nav caret className='text-white header-text'>
                        TH
                      </DropdownToggle>
                      <DropdownMenu left>
                        <DropdownItem>
                          EN
                        </DropdownItem>
                        <DropdownItem>
                          TH
                        </DropdownItem>
                      </DropdownMenu>
                    </UncontrolledDropdown>
                  </>
                }
              </Nav>
              {isOpen == false &&
                <Form className="form-inline ml-auto" onSubmit={searchSubmit}>
                  <UncontrolledDropdown >
                    <DropdownToggle nav caret className='text-white header-text'>
                      TH
                    </DropdownToggle>
                    <DropdownMenu right>
                      <DropdownItem className="text-black">
                        EN
                      </DropdownItem>
                      <DropdownItem className="text-black">
                        TH
                      </DropdownItem>
                    </DropdownMenu>
                  </UncontrolledDropdown>
                  <input className='header-input header-text text-white' onChange={(event) => {
                    setSearchInput(event.target.value)
                  }} />
                  <button type='submit' style={{ border: 'none', backgroundColor: 'transparent' }}>
                    <i aria-hidden={true} className="fa fa-search fa-lg header-icon" />
                  </button>
                  <Nav className="ml-4 mr-5" navbar>
                    <a href='/login'>
                      <i class="fa fa-user-circle fa-2x header-icon not-A" style={{ color: `${login_status[0]}` }} />
                      {/* <Button className="btn-round mr-3" color="warning" type="button" style={{ backgroundColor: '#fff200', border: '#fff200', color: 'black', 'font-size': 13 }}>
                      เข้าสู่ระบบ
                    </Button> */}
                    </a>
                  </Nav>
                </Form>
              }
            </Collapse>
            {/* </div> */}
          </Navbar>
        </div>
      </div >
      <div className="header-mt-4"></div>
    </>
  );
}

export default SectionNavigation;
