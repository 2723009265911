import React from 'react'
import {
  Button,
  Container,
  Card
} from "reactstrap";

export default function DelAccTH() {
  return (
    <>
      <Container className='pt-5'>
        <Card className='warning bg-img'>
<h1>ลบบัญชี MVHubPlus</h1>
<br></br><br></br>
1. เข้าใช้งาน Application MVHubPlus บนอุปกรณ์ Android และ iOS<br></br>
2. ไปที่เมนู “เพิ่มเติม”<br></br>
3. เข้าสู่ระบบด้วยบัญชีที่คุณต้องการลบ<br></br>
4. ไปที่เมนู “เพิ่มเติม” <br></br>
5. ไปที่ “ข้อมูลของฉัน”<br></br>
6. มองหาปุ่ม “ลบบัญชีผู้ใช้” ที่ด้านล่าง<br></br>
7. ศึกษาและยอมรับเงื่อนไขการ “ลบบัญชีผู้ใช้” และกรุณาตรวจสอบการสมัครสมาชิกของคุณ คุณสามารถตรวจสอบการสมัครสมาชิกของคุณโดยการแตะที่ปุ่ม “โปรดตรวจสอบการชำระเงินและการสมัครใช้บริการ”<br></br>
*** การลบบัญชีผู้ใช้ ไม่ได้ถือว่าเป็นการยกเลิกการสมัครสมาชิกของคุณโดยอัตโนมัติ ***<br></br>
8. กด “ต่อไป”<br></br>
9. ระบุอีเมลที่ต้องการให้ระบบส่งรหัสยืนยันการลบข้อมูล<br></br>
10. ตรวจสอบอีเมลที่ระบุบน Application บนอุปกรณ์ Android และ iOS เพื่อรับรหัสยืนยันการลบข้อมูล<br></br>
11. นำรหัสยืนยันการลบข้อมูลจากอีเมลมาใส่ใน Application MVHubPlus บนอุปกรณ์ Android และ iOS<br></br>
12. แตะที่ปุ่ม “ลบบัญชีผู้ใช้” เพื่อยืนยันการลบข้อมูล
<br></br><br></br><br></br><br></br>
<Button color='danger' onClick={() => window.open('https://mvhubplus2.page.link/u9DC')}>Delete Account on App</Button>
        </Card>
      </Container>
    </>
  )
}
