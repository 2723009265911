import axios from 'axios';
import React, { Component } from 'react';
import img from '../../assets/img/body/MVHUB+-1.png'
import open from '../../assets/img/body/svg/chevron_left_white_48dp.svg'
import close from '../../assets/img/body/svg/chevron_right_white_48dp.svg'
import 'shaka-player/dist/controls.css';
import '../../assets/css/controls.css'
import "react-responsive-carousel/lib/styles/carousel.min.css"; // carousel css
import Slider from "react-slick";


const shaka = require('shaka-player/dist/shaka-player.ui.js');

class VideoPlayer extends Component {

	constructor(props) {
		super(props);

		this.slide = this.slide.bind(this)

		this.state = {
			url: this.props.url,
			nextUrl: this.props.nextUrl,
			beforeUrl: this.props.previousUrl,
			lastEp: this.props.lastEp,
			license: this.props.license,
			ep: this.props.ep,
			poster: this.props.poster,
			nowTime: 0,
			isDrag: false
		};

		//Creating reference to store video component on DOM
		this.videoComponent = React.createRef();

		//Creating reference to store video container on DOM
		this.videoContainer = React.createRef();

		this.horizonEp = React.createRef()
		this.horizonLanguage = React.createRef()
		//Initializing reference to error handlers
		this.onErrorEvent = this.onErrorEvent.bind(this);
		this.onError = this.onError.bind(this);
	}

	onErrorEvent(event) {
		// Extract the shaka.util.Error object from the event.
		this.onError(event.detail);
	}

	onError(error) {
		// Log the error.
		console.error('Error code', error.code, 'object', error);
	}

	slide(y) {
		if (this.slider === null)
			return 0
		if (y > 0) {
			if (this.slider.innerSlider.state.currentSlide != 0) {
				this.slider.slickPrev()
			}
		} else {
			if (parseInt(this.slider.innerSlider.state.currentSlide + 6) != parseInt(this.slider.innerSlider.state.slideCount))
				this.slider.slickNext()
		}
	}
	async componentDidMount() {
		const name = this.props.datas.current.name
		const epName = this.props.datas.current.episode.filter(data => data.ep_id == this.props.ep[1])
		if (this.state.url !== undefined && this.state.license !== undefined && this.state.url !== null && this.state.license !== null) {

			//Link to MPEG-DASH video
			var manifestUri = this.state.url
			// var manifestUri = this.props.url
			const nextUrl = this.state.nextUrl
			let nextIcon, nextText
			if (this.state.nextUrl.split('/').pop() == 'EP.0' || this.state.nextUrl.split('/').pop() == 'undefined') {
				nextIcon = ''
				nextText = ''
			} else {
				nextIcon = 'skip_next'
				nextText = 'Next'
			}
			const beforeUrl = this.state.beforeUrl
			let beforeIcon
			if (this.state.beforeUrl.split('/').pop() == 'EP.0') {
				beforeIcon = ''
			} else {
				beforeIcon = 'skip_previous'
			}
			const licenseServer = `https://wv.miratech.io/wvproxy/clicense?contentid=${this.state.license}`;
			const ep = this.state.ep
			//Getting reference to video and video container on DOM
			const video = this.videoComponent.current;
			const videoContainer = this.videoContainer.current;

			//Initialize shaka player
			var player = new shaka.Player(video);





			//android
			if (this.state.url.split('.').pop() != 'm3u8') {
				player.getNetworkingEngine().registerRequestFilter(function (type, request) {
					if (type == shaka.net.NetworkingEngine.RequestType.LICENSE) {
						request.headers["Content-Type"] = "application/json";

					}
				});

				player.configure({
					drm: {
						servers: {
							'com.widevine.alpha': licenseServer,
							'com.microsoft.playready': 'https://mspr.miratech.io/playready/rightsmanager.asmx',
						},
						advanced: {
							'com.widevine.alpha': {
								'persistentStateRequired': true,
								'videoRobustness': 'SW_SECURE_CRYPTO',
								'audioRobustness': 'SW_SECURE_CRYPTO'
							}
						}
					}
				});
			}

			// Button skip
			if (this.state.nextUrl != undefined && this.state.nextUrl != null) {
				shaka.ui.SkipButton = class extends shaka.ui.Element {
					constructor(parent, controls) {
						super(parent, controls);

						// The actual button that will be displayed
						this.div_ = document.createElement('div')
						this.button_ = document.createElement('span');
						this.text_ = document.createElement('span')
						this.div_.classList.add('d-flex')
						this.div_.classList.add('align-items-center')
						this.button_.classList.add('material-icons-round');
						this.button_.textContent = nextIcon;
						this.text_.classList.add('menu-text')
						this.text_.innerHTML = nextText
						this.parent.appendChild(this.div_);
						this.div_.appendChild(this.button_)
						this.div_.appendChild(this.text_)

						// Listen for clicks on the button to start the next playback
						this.eventManager.listen(this.div_, 'click', () => {
							window.location.href = nextUrl
							// const nextManifest = 'https://vod-mvhub.sky-cdn.com/prod_mvhub_v2/_definst_/path1/V2H_TVBPMMTLG00165/smil:V2H_TVBPMMTLG00165_ep2.smil/manifest.mpd'

							// // shaka.ui.Element gives us access to the player object as member of the class
							// this.player.load(nextManifest);
							// if (this.isLastManifest(nextManifest)) {
							// 	// This is the last video. Change icon and aria label.
							// 	this.button_.icon = 'goToBeginningIcon';
							// 	// Screen readers will read "Go to the first video button"
							// 	this.button_.setAttribute('aria-label', 'Go to the first video');
							// } else if (this.isFirstManifest(nextManifest)) {
							// 	// Change icon and aria label back to the skip state
							// 	this.button_.icon = 'skipIcon';
							// 	this.button_.setAttribute('aria-label', 'Skip');
							// }
						});
					}
				};
				// Factory that will create a button at run time.
				shaka.ui.SkipButton.Factory = class {
					/** @override */
					create(rootElement, controls) {
						return new shaka.ui.SkipButton(rootElement, controls);
					}
				};
				// Register our factory with the controls, so controls can create button instances.
				shaka.ui.Controls.registerElement('skip', new shaka.ui.SkipButton.Factory());
			}

			// Button before
			shaka.ui.BeforeButton = class extends shaka.ui.Element {
				constructor(parent, controls) {
					super(parent, controls);

					// The actual button that will be displayed
					this.button_ = document.createElement('button');
					this.button_.classList.add('material-icons-round');
					this.button_.textContent = beforeIcon;
					this.parent.appendChild(this.button_);

					// Listen for clicks on the button to start the next playback
					this.eventManager.listen(this.button_, 'click', () => {
						window.location.href = beforeUrl

						// shaka.ui.Element gives us access to the player object as member of the class
						if (window.location.href.split('/').pop() == 'EP.1') {
							// This is the last video. Change icon and aria label.
							this.button_.textContent = beforeIcon;
							// Screen readers will read "Go to the first video button"
							this.button_.setAttribute('aria-label', 'Go to the first video')
						}
					});
				}
			};
			// Factory that will create a button at run time.
			shaka.ui.BeforeButton.Factory = class {
				/** @override */
				create(rootElement, controls) {
					return new shaka.ui.BeforeButton(rootElement, controls);
				}
			};
			// Register our factory with the controls, so controls can create button instances.
			shaka.ui.Controls.registerElement('before', new shaka.ui.BeforeButton.Factory());

			///////////////////////////////////////////////////////////////////////////////////////////////////////////
			shaka.ui.test = class extends shaka.ui.Element {
				constructor(parent, controls) {
					super(parent, controls);
					this.div_ = document.createElement('div')
					this.button_ = document.createElement('span');
					this.text_ = document.createElement('span')
					this.div_.classList.add('d-flex')
					this.div_.classList.add('align-items-center')
					this.button_.classList.add('material-icons-round');
					// this.button_.classList.add('list');
					this.button_.textContent = 'format_list_numbered';
					this.text_.classList.add('menu-text')
					this.text_.innerHTML = 'Episode'
					this.parent.appendChild(this.div_);
					this.div_.appendChild(this.button_)
					this.div_.appendChild(this.text_)

					this.eventManager.listen(this.div_, 'click', () => {
						let lang = document.querySelector('.horizon-language');
						lang.setAttribute('show', 'false')
						let ep = document.querySelector('.horizon-ep');
						let show = ep.attributes.show
						Object(show).value == 'false' ? ep.setAttribute('show', 'true') : ep.setAttribute('show', 'false')
					})
					this.eventManager.listen(this.controls, 'timeandseekrangeupdated', async () => {
						let displayTime = this.controls.getDisplayTime().toFixed(0);
						const seekRange = this.player.seekRange();

						if (displayTime >= Math.floor(seekRange.end) && Math.floor(seekRange.end) != 0) {
							if (nextUrl.split('/').pop() != 'EP.0' && nextUrl.split('/').pop() != 'undefined')
								window.location.href = nextUrl
						}
					});
				}
			};
			// Factory that will create a button at run time.
			shaka.ui.test.Factory = class {
				/** @override */
				create(rootElement, controls) {
					return new shaka.ui.test(rootElement, controls);
				}
			};
			// Register our factory with the controls, so controls can create button instances.
			shaka.ui.Controls.registerElement('test', new shaka.ui.test.Factory());

			///////////////////////////////////////////////////////////////////////////////

			///////////////////////////////////////////////////////////////////////////////////////////////////////////
			shaka.ui.setting = class extends shaka.ui.Element {
				constructor(parent, controls) {
					super(parent, controls);
					this.div_ = document.createElement('div')
					this.button_ = document.createElement('span');
					this.text_ = document.createElement('span')
					this.div_.classList.add('d-flex')
					this.div_.classList.add('align-items-center')
					this.button_.classList.add('material-icons-round');
					// this.button_.classList.add('list');
					this.button_.textContent = 'subtitles';
					this.text_.classList.add('menu-text')
					this.text_.innerHTML = 'Language'
					this.parent.appendChild(this.div_);
					this.div_.appendChild(this.button_)
					this.div_.appendChild(this.text_)

					this.eventManager.listen(this.div_, 'click', () => {
						let ep = document.querySelector('.horizon-ep');
						ep.setAttribute('show', 'false')
						let lang = document.querySelector('.horizon-language');
						let show = lang.attributes.show
						Object(show).value == 'false' ? lang.setAttribute('show', 'true') : lang.setAttribute('show', 'false')
					})
				}
			};
			// Factory that will create a button at run time.
			shaka.ui.setting.Factory = class {
				/** @override */
				create(rootElement, controls) {
					return new shaka.ui.setting(rootElement, controls);
				}
			};
			// Register our factory with the controls, so controls can create button instances.
			shaka.ui.Controls.registerElement('setting', new shaka.ui.setting.Factory());

			///////////////////////////////////////////////////////////////////////////////

			//////////////////////////////////////////////////////////////////////////////////////////////////////////
			shaka.ui.name = class extends shaka.ui.Element {
				constructor(parent, controls) {
					super(parent, controls);

					this.name_ = document.createElement('div');
					this.name_.classList.add('d-flex')
					this.name_.classList.add('align-items-center')
					this.name_.classList.add('video-name')
					this.name_.style.fontSize = '0.9rem'
					this.name_.textContent = `${name} ${epName[0].ep_name}`;
					this.parent.appendChild(this.name_);

				}
			};
			// Factory that will create a button at run time.
			shaka.ui.name.Factory = class {
				/** @override */
				create(rootElement, controls) {
					return new shaka.ui.name(rootElement, controls);
				}
			};
			// Register our factory with the controls, so controls can create button instances.
			shaka.ui.Controls.registerElement('name', new shaka.ui.name.Factory());

			///////////////////////////////////////////////////////////////////////////////

			///////////////////////////////////////////////////////////////////////////////////////////////////////////
			shaka.ui.play = class extends shaka.ui.Element {
				constructor(parent, controls) {
					super(parent, controls);

					// The actual button that will be displayed
					this.button_ = document.createElement('button');
					this.button_.classList.add('material-icons-round');
					this.button_.classList.add('playCenter');
					this.button_.textContent = 'play_circle_outline';
					this.parent.appendChild(this.button_);

					// Listen for clicks on the button to start the next playback
					this.eventManager.listen(this.controls, 'timeandseekrangeupdated', async () => {
						if (video.paused) {
							this.button_.textContent = 'play_circle_outline';
						} else {
							this.button_.textContent = 'pause_circle_outline';
						}
					})

					this.eventManager.listen(this.button_, 'click', () => {
						if (video.paused) {
							video.play()
						} else {
							video.pause()
						}
					});
				}
			};
			// Factory that will create a button at run time.
			shaka.ui.play.Factory = class {
				/** @override */
				create(rootElement, controls) {
					return new shaka.ui.play(rootElement, controls);
				}
			};
			// Register our factory with the controls, so controls can create button instances.
			shaka.ui.Controls.registerElement('play', new shaka.ui.play.Factory());

			///////////////////////////////////////////////////////////////////////////////

			///////////////////////////////////////////////////////////////////////////////////////////////////////////
			shaka.ui.replay = class extends shaka.ui.Element {
				constructor(parent, controls) {
					super(parent, controls);

					// The actual button that will be displayed
					this.button_ = document.createElement('button');
					this.button_.classList.add('material-icons-round');
					this.button_.classList.add('replay_10');
					this.button_.textContent = 'replay_10';
					this.parent.appendChild(this.button_);

					// Listen for clicks on the button to start the next playback
					this.eventManager.listen(this.button_, 'click', () => {
						video.currentTime -= 10
					});
				}
			};
			// Factory that will create a button at run time.
			shaka.ui.replay.Factory = class {
				/** @override */
				create(rootElement, controls) {
					return new shaka.ui.replay(rootElement, controls);
				}
			};
			// Register our factory with the controls, so controls can create button instances.
			shaka.ui.Controls.registerElement('replay', new shaka.ui.replay.Factory());

			///////////////////////////////////////////////////////////////////////////////

			///////////////////////////////////////////////////////////////////////////////////////////////////////////
			shaka.ui.forward = class extends shaka.ui.Element {
				constructor(parent, controls) {
					super(parent, controls);

					// The actual button that will be displayed
					this.button_ = document.createElement('button');
					this.button_.classList.add('material-icons-round');
					this.button_.classList.add('forward_10');
					this.button_.textContent = 'forward_10';
					this.parent.appendChild(this.button_);

					// Listen for clicks on the button to start the next playback
					this.eventManager.listen(this.button_, 'click', () => {
						video.currentTime += 10
					});
				}
			};
			// Factory that will create a button at run time.
			shaka.ui.forward.Factory = class {
				/** @override */
				create(rootElement, controls) {
					return new shaka.ui.forward(rootElement, controls);
				}
			};
			// Register our factory with the controls, so controls can create button instances.
			shaka.ui.Controls.registerElement('forward', new shaka.ui.forward.Factory());

			///////////////////////////////////////////////////////////////////////////////

			///////////////////////////////////////////////////////////////////////////////////////////////////////////
			shaka.ui.screen = class extends shaka.ui.Element {
				constructor(parent, controls) {
					super(parent, controls);

					// The actual button that will be displayed
					this.button_ = document.createElement('button');
					this.button_.classList.add('material-icons-round');
					this.button_.classList.add('screen_10');
					this.button_.textContent = 'fullscreen';
					this.parent.appendChild(this.button_);

					// Listen for clicks on the button to start the next playback
					this.eventManager.listen(document, 'fullscreenchange', () => {
						if (document.fullscreenElement) {
							this.button_.textContent = 'fullscreen_exit';
						} else {
							this.button_.textContent = 'fullscreen';
						}
					});
					this.eventManager.listen(this.button_, 'click', async () => {
						if (document.fullscreenElement) {
							await document.exitFullscreen()
						} else {
							await document.documentElement.requestFullscreen()
						}
					});
				}
			};
			// Factory that will create a button at run time.
			shaka.ui.screen.Factory = class {
				/** @override */
				create(rootElement, controls) {
					return new shaka.ui.screen(rootElement, controls);
				}
			};
			// Register our factory with the controls, so controls can create button instances.
			shaka.ui.Controls.registerElement('screen', new shaka.ui.screen.Factory());

			///////////////////////////////////////////////////////////////////////////////

			///////////////////////////////////////////////////////////////////////////////////////////////////////////
			shaka.ui.nextBtn = class extends shaka.ui.Element {
				constructor(parent, controls) {
					super(parent, controls);

					// The actual button that will be displayed
					this.button_ = document.createElement('button');
					this.button_.textContent = 'ตอนต่อไป';
					this.button_.classList.add('next-btn');
					this.button_.setAttribute('show', 'true');
					this.parent.appendChild(this.button_);

					// Listen for clicks on the button to start the next playback
					this.eventManager.listen(this.button_, 'click', () => {
						const button = document.querySelector('.shaka-controls-container')
						button.setAttribute('shown', 'false')
						window.location.href = nextUrl
					});
				}
			};
			// Factory that will create a button at run time.
			shaka.ui.nextBtn.Factory = class {
				/** @override */
				create(rootElement, controls) {
					return new shaka.ui.nextBtn(rootElement, controls);
				}
			};
			// Register our factory with the controls, so controls can create button instances.
			shaka.ui.Controls.registerElement('nextBtn', new shaka.ui.nextBtn.Factory());

			///////////////////////////////////////////////////////////////////////////////
			//Setting UI configuration JSON object
			const uiConfig = {
				seekBarColors: {
					base: 'rgba(255, 255, 255, 0.3)',
					buffered: 'rgba(255, 255, 255, 0.9)',
					played: 'rgb(255, 242, 0)',
					adBreaks: 'rgb(255, 242, 0)',
				},
				volumeBarColors: {
					base: 'rgba(255, 255, 255, 0.3)',
					level: 'rgb(255, 242, 0)'
				},
				addBigPlayButton: true
			};

			//Configuring elements to be displayed on video player control panel
			uiConfig['controlPanelElements'] = ['seekbar', 'replay', 'time_and_duration', 'name', 'spacer', 'test', 'setting', 'skip', 'forward', 'spacer', 'mute', 'volume', 'fullscreen'];
			uiConfig['overflowMenuButtons'] = ['quality', 'language', 'captions', 'playback_rate']
			//Setting up shaka player UI
			const ui = new shaka.ui.Overlay(player, videoContainer, video);

			player.configure({
				streaming: {
					bufferingGoal: 100,
					bufferBehind: 100,
					rebufferingGoal: 60
				}
			})

			ui.configure(uiConfig); //configure UI
			ui.getControls();
			// console.log(player.getConfiguration())
			// Listen for error events.
			player.addEventListener('error', this.onErrorEvent);

			// Try to load a manifest.
			// This is an asynchronous process.

			player.load(manifestUri, ep[2]).then(function () {

				setInterval(async () => {
					if (localStorage.getItem('auth')) {
						await axios.put(`${process.env.REACT_APP_API_CONTENT2}/update/continuewatching`, {
							"content_id": String(ep[0]),
							"ep_id": String(ep[1]),
							"type": "serie",
							"current": String(video.currentTime.toFixed(0)) + '000',
							"duration_time": String(video.duration.toFixed(0)) + '000'
						}, {
							headers: {
								Authorization: `Bearer ${window.localStorage.getItem('auth')}`
							},
						}).then(() => {
							// console.log(result)
						})
					}
				}, 120 * 1000)
				let timerId = null;
				function onStateChange(status) {
					clearTimeout(timerId);

					// Show the button, then quickly hide it again.  The transition will animate hiding it.
					const button = document.querySelector('.status-button');
					button?.classList.add(status);
					// button.value = 'skip_next'

					timerId = setTimeout(() => button.classList.value = 'status-button', 200);

					if (video.currentTime < (video.duration - 90)) {
						const button = document.querySelector('.next-btn');
						button?.setAttribute('show', 'false')
					}
				}

				const videoOnpress = (event) => {
					if (event.code == 'Digit0') {
						video.currentTime = 0
					}
					if (event.code == 'Digit1') {
						video.currentTime = (10 * video.duration) / 100
					}
					if (event.code == 'Digit2') {
						video.currentTime = (20 * video.duration) / 100
					}
					if (event.code == 'Digit3') {
						video.currentTime = (30 * video.duration) / 100
					}
					if (event.code == 'Digit4') {
						video.currentTime = (40 * video.duration) / 100
					}
					if (event.code == 'Digit5') {
						video.currentTime = (50 * video.duration) / 100
					}
					if (event.code == 'Digit6') {
						video.currentTime = (60 * video.duration) / 100
					}
					if (event.code == 'Digit7') {
						video.currentTime = (70 * video.duration) / 100
					}
					if (event.code == 'Digit8') {
						video.currentTime = (80 * video.duration) / 100
					}
					if (event.code == 'Digit9') {
						video.currentTime = (90 * video.duration) / 100
					}
					if (event.code == 'KeyM') {
						if (video.muted == true) {
							onStateChange('volumeUp')
							video.muted = false
						} else {
							onStateChange('mute')
							video.muted = true
						}
					}
					if (event.code == 'Space') {
						video.paused ? video.play() : video.pause()
					}
					if (event.code == 'ArrowRight') {
						onStateChange('forward')
						video.currentTime += 10
					}
					if (event.code == "ArrowLeft") {
						onStateChange('replay')
						video.currentTime -= 10
					}
					if (event.code == 'ArrowUp') {
						onStateChange('volumeUp')
						video.volume = video.volume >= 0.9 ? 1.0 : parseFloat(video.volume + 0.1).toFixed(1)
					}
					if (event.code == 'ArrowDown') {
						onStateChange('volumeDown')
						video.volume = video.volume <= 0.1 ? 0.0 : parseFloat(video.volume - 0.1).toFixed(1)
					}
					if (event.code == 'KeyF') {
						if (document.fullscreenElement) {
							document.exitFullscreen()
						} else {
							document.getElementById('video-container').requestFullscreen()
						}

					}
				}
				if (nextIcon != '') {
					video.addEventListener('timeupdate', () => {
						if (video.currentTime >= (video.duration - 90)) {
							// const button = document.querySelector('.shaka-controls-container');
							const button = document.querySelector('.next-btn');
							button.show = 'true'
							button.setAttribute('show', 'true')
							// button.classList.add('.show_true');
						}
					});
				}
				document.addEventListener('keydown', (event) => videoOnpress(event))
				// video.addEventListener('pause', () => onStateChange('pause'));
				// video.addEventListener('play', () => onStateChange('play'));

				player.getAudioLanguages().map((lang, index) => {
					const lang_ = document.getElementById('lang')
					const button_ = document.createElement('div')
					if (index === 0)
						button_.classList.add('selected-lang')
					button_.innerHTML = lang
					lang_.appendChild(button_)
					button_.addEventListener('click', (event) => {
						player.selectAudioLanguage(event.target.innerHTML)

						const selectedLang = document.getElementsByClassName('selected-lang')
						while (selectedLang.length > 0) {
							selectedLang[0].classList.remove('selected-lang')
						}
						event.target.classList.add('selected-lang')
					})
				})

				const sub_ = document.getElementById('sub')
				const close_ = document.createElement('div')
				close_.innerHTML = 'close'
				close_.classList.add('selected-sub')
				sub_.appendChild(close_)
				close_.addEventListener('click', () => {
					player.setTextTrackVisibility(0)
					const selectedSub = document.getElementsByClassName('selected-sub')
					while (selectedSub.length > 0) {
						selectedSub[0].classList.remove('selected-sub')
					}
					close_.classList.add('selected-sub')
				})
				player.getTextTracks().map(text => {
					const button_ = document.createElement('div')
					button_.innerHTML = text.language
					sub_.appendChild(button_)
					button_.addEventListener('click', (event) => {
						player.selectTextTrack(event.target.innerHTML)
						player.selectTextLanguage(event.target.innerHTML)
						player.setTextTrackVisibility(1)

						const selectedSub = document.getElementsByClassName('selected-sub')
						while (selectedSub.length > 0) {
							selectedSub[0].classList.remove('selected-sub')
						}
						button_.classList.add('selected-sub')
					})
				})
			}).catch(this.onError);  // onError is executed if the asynchronous load fails.	
		}
		document.getElementById('horizon-ep').addEventListener('wheel', (e) => {
			this.slide(e.wheelDelta)
		})
	}

	render() {
		/*
		Returning video with a container. Remember, when setting up shaka player with custom UI, you must
		add your video component inside a container
		The container will be used by shaka player to add your customized UI for the player
		*/

		const settingsone = {
			dots: false,
			arrows: false,
			infinite: false,
			speed: 300,
			slidesToShow: 6,
			vertical: true,
			verticalSwiping: true,
			swipeToSlide: true,
		};

		function SampleNextArrow(props) {
			const { className, style, onClick } = props;
			return (
				<div
					className={className}
					style={{ ...style, display: "block" }}
					onClick={onClick}
				>
					«
				</div>
			);
		}

		function SamplePrevArrow(props) {
			const { className, style, onClick } = props;
			return (
				<div
					className={className}
					style={{ ...style, display: "block" }}
					onClick={onClick}
				>
					»
				</div>
			);
		}

		const settingstwo = {
			useCSS: true,
			arrows: true,
			centerMode: false,
			infinite: false,
			speed: 500,
			slidesToShow: 10,
			slidesToScroll: 1,
			swipeToSlide: true,
			rows: 1,
			adaptiveHeight: true,
			lazyLoad: true,
			prevArrow: <SampleNextArrow />,
			nextArrow: <SamplePrevArrow />,
			responsive: [
				{
					breakpoint: 1500,
					settings: {
						slidesToShow: 7,
						slidesToScroll: 1,
					}
				},
				{
					breakpoint: 1025,
					settings: {
						slidesToShow: 5,
						slidesToScroll: 1,
					}
				},
				{
					breakpoint: 800,
					settings: {
						slidesToShow: 4,
						slidesToScroll: 1,
					}
				},
				{
					breakpoint: 480,
					settings: {
						slidesToShow: 2,
						slidesToScroll: 1,
					}
				}
			]
		};

		const epClick = (id, ep_name, premium) => {
			if (this.state.isDrag) {
				return null
			} else {
				if (premium == '1') {
					return window.location.href = `/vdo/${id}/ep/${ep_name}`
				} else {
					return alert('กรุณาสมัครแพ็คเกจ')
				}
			}
		}
		const renderEp = this.props.datas.current.episode.map((data) => {
			return (
				<div className='position-relative' onClick={() => epClick(this.props.id, data.ep_name, data.premium)}>
					<img style={{ width: '100%' }} src={data.ep_img} alt='' />
					<div className='nameTag'>{data.ep_name}</div>
				</div>
			)
		})
		const disableEp = (event) => {
			console.log(event.target)
			if (event.target !== this.horizonEp.current || event.target !== this.horizonLanguage.current) {
				document.getElementById('horizon-ep').setAttribute('show', 'false')
				document.getElementById('horizon-language').setAttribute('show', 'false')
			}
		}
		const enableEp = () => {
			document.getElementById('horizon-ep').setAttribute('show', 'true')
		}
		return (
			<>
				<div className="video-container" id='video-container' ref={this.videoContainer} onClick={disableEp}>
					<img className="top-right logo" src={img} alt='mvtv' onClick={() => window.location.href = '/'} />
					<video
						id='shaka'
						autoPlay
						className="youtube-theme shaka-video"
						ref={this.videoComponent}
						poster={this.state.poster}
					/>
					<span class="status-button material-icons-round"></span>
					<button className='next-btn' onClick={() => window.location.href = this.state.nextUrl}>ตอนต่อไป</button>
					{/* <div className='enable-ep' id='enable-ep' show='true' onMouseOver={enableEp}>
						<img src={open} />
					</div> */}
					<div id='horizon-language' className='horizon-language d-flex justify-content-around' show='false' ref={this.horizonLanguage}>
						<div>
							<div>
								Language
							</div>
							<div id='lang' className='scroll'>
							</div>
						</div>
						<div>
							<div>
								Subtitle
							</div>
							<div id='sub' className='scroll'>
							</div>
						</div>
					</div>
					<div id='horizon-ep' className='horizon-ep' show='false' ref={this.horizonEp}>
						{/* <div className='disable-ep' onClick={disableEp}>
							<span>
								<img src={close} alt='disable' />
							</span>
							<span>
								<a>
									ปิด
								</a>
							</span>
						</div> */}
						{/* <div className='position-relative'>
							<Slider ref={slider => this.slider = slider} className='mt-3 mb-3 mr-3 ml-3 position-absolute' {...settingsone} afterChange={() => this.setState({ isDrag: false })} onSwipe={() => this.setState({ isDrag: true })}>
								{renderEp}
							</Slider>
						</div> */}
						<Slider {...settingstwo} ref={slider => this.slider = slider} className='mt-2 mb-1 mr-1 ml-1' afterChange={() => this.setState({ isDrag: false })} onSwipe={() => this.setState({ isDrag: true })}>
							{renderEp}
						</Slider>
					</div>
				</div>
			</>
		);
	}
}

export default VideoPlayer;