import React, { useState, useEffect } from "react";
import { Switch, Route } from "react-router-dom";
import '../../assets/css/style.css'

import SectionNavigation from "../index-sections/SectionNavigation.js";
import Body from "../package-sections/Body";

import loadingImg from '../../assets/img/body/loading.gif'
import axios from "axios";
import { getOS } from "getOs";
import PackRegis from "./PackRegis";
import Pay from "./Pay";
import Result from "./Result";
import ResultFail from "./ResultFail";
import PayKbank from "./PayKbank";
import PayKrungsri from "./PayKrungsri";
import FooterFixed from "components/Footers/FooterFixed";
import FooterBlack from "components/Footers/FooterBlack";
import { getBrowser } from "getBrowser";

function PackagePage() {
//console.log(window.localStorage.getItem('auth'))

  const [datas, setDatas] = useState()
  const [isLoading, setIsLoading] = useState(true)
  useEffect(() => {
    axios.post(`${process.env.REACT_APP_API_CONTENT2}/allpackage/0`, { device: getBrowser() })
      .then(result => {
        setDatas(result)
        setIsLoading(false)
      })
  }, [])
  return (
    <>
      {isLoading === true &&
        <div className='bg-loading'>
          <img src={loadingImg} className='loading-img' alt='loading' />
        </div>
      }
      {isLoading === false &&
        <div className="bg-img">
          <SectionNavigation />
          <Switch>
            <Route path={`/package/pay/:ref`} exact>
              <Pay />
            </Route>
            <Route path={'/package/result/fail'} exact>
              <ResultFail />
            </Route>
            <Route path={'/package/result'} exact>
              <Result />
            </Route>
            <Route path={'/package/kbank'} exact>
              <PayKbank />
            </Route>
            <Route path={'/package/krungsri'} exact>
              <PayKrungsri />
            </Route>
            <Route path={`/package/:id`} exact>
              <PackRegis datas={datas} />
            </Route>
            <Route path={`/package`} exact>
              <Body datas={datas} />
            </Route>
          </Switch>
          <FooterBlack />
        </div>
      }
    </>
  );
}

export default PackagePage;
