import axios from 'axios';
import React, { useState } from 'react'
import { Link } from 'react-router-dom';
import {
    Button,
    Input,
    InputGroupAddon,
    InputGroup,
    Container,
    Row,
    Col,
} from "reactstrap";

export default function Body(props) {
    const [code, setCode] = useState()
    const redeem = (event) => {
        event.preventDefault()
        if (localStorage.getItem('auth')) {
            axios.post(`${process.env.REACT_APP_API}/v1/package/redeem`, {
                'redeem': code
            }, {
                headers: {
                    Authorization: `Bearer ${window.localStorage.getItem('auth')}`
                }
            }).then(result => alert(result.data.res_msg))
        } else {
            alert('กรุณาเข้าสู่ระบบก่อน')
        }
    }
    const renderPackage = props.datas.data.res_data.map(data => {
        return (
            <Col>
                <Link to={`/package/${data.product_id}`}>
                    <img className='package-img silhouette mt-5 mb-2 pl-3 pr-3' src={data.img_web} alt={data.pack_name} style={{'max-width':'100%'}}/>
                </Link>
            </Col>
        )
    })
    return (
        <>
            <div className='bg-img'>
                <Container className='pt-5 package-container'>
                    <Row>
                        {renderPackage}
                    </Row>
                    <br />
                    <Row className="justify-content-center mt-1 pb-1">
                        <Col sm='12' md='10' lg='10'>
                            <form onSubmit={redeem}>
                                <InputGroup size='lg'>
                                    <InputGroupAddon addonType="prepend">
                                        <Button color='success' className='pb-1 package-btn' type='submit'>ใส่รหัสโปรโมชั่น
                                        </Button>
                                    </InputGroupAddon>
                                    <Input onChange={(event) => setCode(event.target.value)} />
                                </InputGroup>
                            </form>
                        </Col>
                    </Row>
                    <br />
                    <br />
                </Container>
            </div>
        </>
    )
}
