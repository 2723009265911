import React, { useEffect, useState, useRef } from 'react'
import { Helmet } from "react-helmet";
import $ from 'jquery';
// import {
//     Button,
//     Col,
//     Row
// } from "reactstrap";

export default function PayKbank(props) {
    // const { ref } = useParams()
    // const [html, setHtml] = useState()
    // const [value, setValue] = useState([])
    // const [vals, setVals] = useState([])
    // const formRef = useRef(null)
    const [IsLoading, setIsLoading] = useState(false)

    const urlSearchParams = new URLSearchParams(window.location.search);
    const params = Object.fromEntries(urlSearchParams.entries());
    $(document).ready(function () {
        $('form').submit();
    });

    useEffect(() => {
        setIsLoading(true)
    }, [])

    if (IsLoading == true) {
        return (
            <>
                <div className='bg-blank'>
                    <form action="https://rt05.kasikornbank.com/payplus/payment.aspx" method="post">

                        <input type="hidden" name="MERCHANT2" id="MERCHANT2" value={params.value1} />
                        <input type="hidden" name="AMOUNT2" id="AMOUNT2" value={params.value2} />
                        <input type="hidden" name="URL2" id="URL2" value={params.value3} />
                        <input type="hidden" name="RESPURL" id="RESPURL" value={params.value4} />
                        <input type="hidden" name="IPCUST2" id="IPCUST2" value={params.value5} />
                        <input type="hidden" name="DETAIL2" id="DETAIL2" value={params.value6} />
                        <input type="hidden" name="INVMERCHANT" id="INVMERCHANT" value={params.value7} />
                        <input type="hidden" name="SHOPID" id="SHOPID" value={params.value8} />
                        <input type="hidden" name="CHECKSUM" id="CHECKSUM" value={params.value9} />
                        <input type="hidden" name="REF1" id="REF1" value={params.value10} />
                        <input type="hidden" name="REF2" id="REF2" value={params.value11} />
                        <input type="hidden" name="INFO1" id="INFO1" value={params.value12} />
                        <input type="hidden" name="INFO2" id="INFO2" value={params.value13} />
                        <input type="hidden" name="TIMEOUT" id="TIMEOUT" value={params.value14} />

                    </form>
                </div>
                <Helmet>
                    <script type="text/javascript" src="https://cdnjs.cloudflare.com/ajax/libs/jquery/3.2.1/jquery.min.js"></script>
                </Helmet>
            </>
        )
    } else {
        return <div className='bg-blank'>Loading...</div>
    }
}
