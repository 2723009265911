import React from "react";
import ReactDOM from "react-dom";
import { BrowserRouter, Route, Redirect, Switch } from "react-router-dom";

// styles
import "../src/assets/css/style.css"
import "bootstrap/scss/bootstrap.scss";
// import "assets/scss/paper-kit.scss";
import "assets/demo/demo.css";
import "assets/demo/react-demo.css";
// pages
import Index from "views/Index.js";
import LoginPage from "views/login-sections/LoginPage.js";
import PackagePage from "views/package-sections/PackagePage.js";
import HitPage from "views/hit-sections/HitPage";
import ComingPage from "views/coming-sections/ComingPage";
import TvPage from "views/tv-section/TvPage";
import Vdo from "views/vdo/Vdo";
import SubPages from "views/sub-sections/SubPages";
import SearchPage from "views/search-sections/SearchPage";
import MessengerCustomerChat from 'react-messenger-customer-chat';
import PolicyPages from "views/policy-sections/PolicyPages";
import AutoLogin from "views/login-sections/AutoLogin.js";
// others

ReactDOM.render(
  <BrowserRouter>
    <Switch>
      <Route path="/" exact render={(props) => <Index {...props} />} />
      <Route path="/sub/:search" exact render={(props) => <SubPages {...props} />} />
      <Route path="/search/:search" exact render={(props) => <SearchPage {...props} />} />
      <Route path="/login" render={(props) => <LoginPage {...props} />} />
      <Route path="/package" render={(props) => <PackagePage {...props} />} />
      <Route path="/hit" render={(props) => <HitPage {...props} />} />
      <Route path="/vdo" render={(props) => <Vdo {...props} />} />
      <Route path="/comingsoon" render={(props) => <ComingPage {...props} />} />
      <Route path="/tv" render={(props) => <TvPage {...props} />} />
      <Route path="/policy" render={(props) => <PolicyPages {...props} />} />
      <Route path="/autologin" render={(props) => <AutoLogin {...props} />} />
      <Redirect to="/" />
    </Switch>
    {/* <MessengerCustomerChat
      pageId="101441408362781"
      appId="920324681810227"
      htmlRef="https://mvhubplus.com/"
    /> */}
  </BrowserRouter>,
  document.getElementById("root")
);
