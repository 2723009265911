import React from 'react'
import {
  Container,
  Card
} from "reactstrap";

export default function ConditionsTH() {
  return (
    <>
      <Container className='pt-5'>
        <Card className='warning bg-img'>
        <h1>Term And Condition</h1><br></br><br></br>
1. Important notice<br></br>
(1.1) Please read the following terms and conditions carefully. By registering for an account (“MVHub Plus Account”), you agree to be bound by these account terms and conditions. (“Account Terms and Conditions”) as may be updated from time to time. and terms of use of MVHub Plus (available at https://mvhubplus.com/policy/term-and-conditions Together they constitute our contract with you for the MVHub Plus Services (the “Contract”). All capitalized words in the Account Terms and Conditions have the same meaning as defined in the Account Terms and Conditions. Use of MVHub Plus unless otherwise noted.<br></br>
(1.2) The MVHub Plus services in Thailand that you access will be provided to you by MV Hub Company Limited, subject to these Account Terms and Conditions. The terms “we” (President), “our” and “us” (Karma) means MV Hub Company Limited.<br></br>
(1.3) We will update these account terms and conditions from time to time. It is your responsibility to check for updates to these account terms and conditions. Because such updates will be effective upon posting on the website. Your continued use of your MVHub Plus account after any such modifications. Whether it has been verified by you or not shall constitute your contract to be bound by such amendments, except as required by applicable law. We will notify you or otherwise obtain your consent for such updates.<br></br><br></br>
2. Free trial on the website www.mvhubplus.com and the MVHub Plus application<br></br>
(2.1) For the avoidance of doubt, clause 2. and “Free Trial” under these Account Terms and Conditions means the free trial available on the Website. www.mvhubplus.com and MVHub Plus application only<br></br>
(2.2) We (or someone on our behalf) may offer a free trial membership to MVHub Plus Premium for a specified period of time. (“Free Trial”) The sub-terms below under this Section 2 will apply when we make the free trial available to you. You will need to register for an MVHub Plus account if you wish to access the free trial. If you have the right to a free trial You will be notified when you become an MVHub Plus Premium member. We, in our sole discretion, will determine whether you are eligible for the free trial and without any liability to you The Terms or the availability of the free trial may be withdrawn or modified at any time with or without notice to you.<br></br>
(2.3) In order to have the right to try it out without charge. You must be a member of MVHub or MVHub Plus.com and provide your complete payment details to one of our authorized third party payment gateways. (Each person is called “Payment Gateways”) including but not limited to the App Store, Google Play and Adyen N.V. and/or their affiliates. (collectively, “Adyen”) Adyen is our authorized direct credit or debit card payment gateway.<br></br>
(2.4) After applying for MVhub membership, you can try watching the first 2 episodes for free (may exclude movies in special categories as specified by MVhub) or as specified during registration. And the objective is to allow new members and some former members to try out the service. Free Trial eligibility for the 2-Episode Free Trial is available at MVhub's sole discretion, and MVhub may limit eligibility or duration to prevent abuse of the 2-Episode Free Trial. MVhub reserves the right to cancel the 2-episode free trial and suspend your account in the event that MVhub deems that you do not already meet MVhub's Terms of Service.<br></br>
(2.5) unless you cancel your subscription plan at least twenty-four (24) hours before the date your free trial ends; We will charge you for the applicable membership fees. (“Membership Fee”) in accordance with Section 3 below, under the membership plan for the MVHub Plus service on a fee-for-service basis that you select. (“Membership Plan”) beginning on the first day after the end of the Free Trial and for the period thereafter in accordance with the applicable billing date. (“Applicable Billing Date”) as determined by us or the Payment Gateway Operator. (depending on the case) will be considered<br></br>
(2.6) Users who have completed a free trial on Smart TV You (as defined in the Terms of Use) do not have the right to a free trial as referred to in this Section 2.<br></br><br></br>
3. Service fee collection<br></br>
(3.1) MVhub Plus will charge a monthly membership fee. After you accept the subscription to the monthly service. Unless we notify you otherwise or unless you cancel your Membership Plan in accordance with Section 5 below, we will renew your Membership Plan on a rolling basis and will charge you the Membership Fee on the billing date. applicable The length of your billing cycle depends on your membership plan. and the applicable billing date may vary depending on the payment gateway you choose.
<br></br>(3.2) If you choose App Store or Google Play as your payment gateway. You will be charged for membership fees via your Apple ID or Google Account (as the case may be) on a recurring basis and according to the billing mechanism of the App Store or Google Play.
<br></br>(3.3) If you choose to pay by credit or debit card directly through Adyen (“Direct Payment”), your Membership Fee will be charged directly to your credit or debit card on a recurring basis. Services and in accordance with Adyen's applicable billing mechanisms. Accordingly, you acknowledge, understand and agree that your credit or debit card information and related billing processes will be processed by Adyen.
<br></br>(3.4) If you choose to pay for your membership plan through any of our other authorized third parties. (Other than as specified in Section 3(b) and Section 3(c), you will be billed on a recurring basis and in accordance with the billing mechanism and applicable terms and conditions of the receiving third party. Such permission
<br></br>(3.5) You are advised to read the terms and conditions of the relevant payment gateway before you provide any payment details or information. to the said gateway We are not responsible for any details or information. provided to the said payment gateway and you agree that we will not be liable for any damages, losses and/or expenses. that you may have to pay in order to do so
<br></br>(3.6) If the full amount of the membership fee has not been paid by the due date. We may suspend your access to the MVHub Plus paid service to which you subscribe until we receive payment in full.
<br></br>(3.7) All membership fees must be paid in advance. and to the extent permitted by law We will not refund any fees or charges. that you have paid (whether related to any unused portion of your membership plan?)
<br></br>(3.8) To perform an Upgrade specified in Section 3(h), you must pay for such Upgrade through the same payment gateway that you use for your existing Subscription Plan. We currently do not support upgrading any subscription plans that use a different payment gateway than the original payment gateway.
<br></br>4. Discount code<br></br><br></br>
<br></br>(4.1) We (or our agents or authorized representatives) may make available to you discount codes. (“MVHub Plus Discount Code”) that you can use to upgrade your free MVHub Plus Service membership to a paid MVHub Plus Service membership plan. As we may determine during the discount period as specified in the discount code or otherwise notified to you. Please note that MVHub Plus discount codes must be used within the specified period, after which they will expire.
<br></br>(4.2) All use of MVHub Plus discount codes and the exercise of rights will be governed by the following cases:
<br></br>(4.2.1) MVHub Plus discount codes may not be resold. or exchange for cash, credit, or any other goods and/or services.
<br></br>(4.2.2) Each MVHub Plus discount code can only be used once. and will automatically expire when used for the purpose of upgrading your membership for a free MVHub Plus service to one of the paid MVHub Plus services as we may determine.
<br></br>(4.2.3) MVHub Plus discount codes can be used as a discount on unlimited free upgrades and/or extensions of membership plans. unless otherwise specified Discount codes may not be used to extend a membership plan beyond a total of three hundred and sixty (360) consecutive days.
<br></br>(4.2.4) unless otherwise specified Current subscribers to MVHub Plus Paid Services may not use a discount code to upgrade their membership plan for any MVHub Plus Paid Services.
<br></br>(4.2.5) We reserve the right to cancel or withdraw the completion and use of a discount code with or without notice in the event of
<br></br>(1) Fraud (actual and attempted)
<br></br>(2) Improper use
<br></br>(3) Violation of the conditions and limitations applicable to the use of discount codes and/or the exercise of rights and/or
<br></br>(4) Reasonably protecting or protecting our interests.
<br></br>(4.2.7) In the event of a conflict between the contents of Section 4.2.2 and the terms and conditions accompanying any discount code, the provisions of Section 4.2.2 shall govern to the extent of the conflict.
<br></br>(4.2.8) By using a discount code You agree to be bound by all applicable terms and conditions relating to the discount code.<br></br><br></br>
5. Canceling your membership plan<br></br>
<br></br>(5.1) Cancellation You may cancel your MVhub membership at any time. You will continue to have access to the MVhub Services until the end of your monthly billing, to the extent permitted by applicable law. Service fees will not be refunded, and MVhub will not provide refunds or credits for uncompleted months of membership or unwatched MVhub content. To cancel, go to your 'Account' page and follow the instructions to cancel. If you cancel your membership Your account will be automatically closed at the end of your current billing period. You can check the date your account will be closed by clicking on 'Billing Details' on the 'Account' page if you signed up with MVhub using your existing account with a third party. Payment and wish to cancel your MVhub membership, you may need to do so through that third party, for example by going to your account connected to that third party and turning off auto-renewal. or cancel your MVhub subscription through that third party. You can also view billing information about your MVhub subscription by going to your account with that third party.<br></br>
(5.2) How do I cancel?<br></br>
(5.2.1) If you pay for your membership plan by direct payment. Please follow the cancellation instructions listed on the page. “My Information” at least twenty-four (24) hours before the next applicable billing date. You can also check the expiration date of your membership plan on the page. “My Profile”<br></br>
(5.2.2) If you pay for your Subscription through the App Store or Google Play, please do so via your chosen payment gateway at least twenty-four (24) hours before the billing date. The next effective amount. For example, you can close automatic renewal or cancel your membership under your account with the payment gateway. For more details, App Store and Google Play users can visit the App Store at https://support.apple.com/th-th/HT202039 and Google Play at https://support.google.com/googleplay/answer/7018481?co=GENIE.Platform%3DAndroid&hl=th&oco=1<br></br><br></br>
6. Our rights and responsibilities<br></br>
(6.1) In addition to our rights set out in these Terms of Use, we may:<br></br>
(6.1.1) Restrict or suspend your MVHub Plus account with or without notice. Where we are of the opinion that such action is appropriate as a result of your use of MVHub Plus services or in the event that you have breached the terms of the contract; and<br></br>
(6.1.2) Change our plan and the membership fee for any membership plan. Occasionally provided that such changes will become effective only at the end of the current billing cycle for your Subscription Plan. Unless otherwise specified by law. We will notify you in advance of any important changes. in our membership plans and membership fees to provide you with an opportunity to cancel your membership plan before such changes become effective.<br></br><br></br>
7. Your responsibilities<br></br>
(7.1) You<br></br>
(7.1.1) shall be strictly and solely responsible for any use of your MVHub Plus Account and any use of the MVHub Plus Services by any person using your login details to your MVHub Plus Account. We It will be considered as your login.<br></br>
(7.2.2) An email address and password must be provided for successful login. or login details to your authorized third party platform account to create your MVHub Plus account.<br></br>
(7.2.3) Must maintain and promptly update any personal information. provided to us and proceed to provide such information as true, accurate, current, and complete at all times<br></br>
(7.2.4) Will not provide any information that is untrue, inaccurate, or not current. or incomplete If we have reasonable grounds to suspect that such information is untrue, inaccurate, or not current. or incomplete We have the right to suspend or restrict your access to the MVHub Plus Service and/or Content and to refuse all current or future use of the MVHub Plus Service and/or Content.<br></br>
(7.2.5) Any violations must be reported. in a contract with our customer service team via the website<br></br>
(7.2.6) must protect your login details and password and ensure they are not disclosed or given to any other person; and<br></br>
(7.3) Agree not to reveal your login details and password. or allow another person to use your login details and password to access your MVHub Plus account, the MVHub Plus Services and/or any Content.<br></br>
(7.4) Our permission for you to access and receive MVHub Plus services and content is conditional upon your strict compliance with the contract.<br></br><br></br>
8. Information and Personal Data<br></br>
(8.1) We collect, process, disclose, store and use your personal information in accordance with the Personal Information Collection Statement. Privacy Statement and Cookie Statement (collectively called Please read this privacy document carefully as it provides information about how we use, share, store and process your personal information. (As defined in the Personal Data Protection Act B.E. 2019, as amended from time to time and any other applicable personal data protection laws and regulations)<br></br>
(8.2) If we request personal information from you You may decline to provide your personal information. However, we may not be able to process your registration for your MVHub Plus account or provide you with the MVHub Plus Services and/or any Content.<br></br>
(8.3) If you register and sign in to your MVHub Plus account using your third party platform (e.g. Facebook) account login details, the information that the third party platform is associated with. Sir This includes but is not limited to your name, username, gender, age, network, language, profile picture, email, account ID and/or region that we may receive in this process. Your information will also be collected and used by us according to the contract. and such use Among other things, it also includes MVHub Plus Services Personalizing Your Customized Content Preferences and sending promotional information about the MVHub Plus Service, our other media entertainment services, or other products and services.<br></br>
(8.4) If you register with your email address. We may use this email address to send you promotional information about the MVHub Plus Services or our other media entertainment services, unless you indicate otherwise during your registration. If you have given your initial consent to receive our emails containing promotional information. But later you do not wish to receive such emails. You may request not to receive such emails using the email opt-out tool. (including but not limited to email address) set out at the end of our emails containing such promotional information.<br></br>
(8.5) You must provide us with information about yourself or your use of the MVHub Plus Services and/or MVHub Plus Account as we reasonably request.<br></br>
(8.5.1) To help us comply with applicable law and to report to any government or regulatory authority. regarding compliance with such laws; and<br></br>
(8.5.2) to evaluate that you have complied is following or will be able to continue to perform all of its obligations under the contract<br></br>
(8.6) without affecting the generality of Section 8(a)-(e).<br></br>
(8.6.1) If you choose App Store or Google Play as your payment gateway. The payment details you provide to the App Store and Google Play are collected solely by your chosen payment gateway. and we will not collect, receive, or view any such information.<br></br>
(8.6.2) If you choose direct payment as your payment method. You will be forwarded from the MVHub Plus channel to the website provided by Adyen. Where all payment details (including but not limited to All information regarding your credit/debit card number, CVV/CVC code and expiration date of your credit/debit card) that you provide is collected by Adyen for and on our behalf. We contract with you to process your credit/debit card information strictly for the sole purpose of processing your payment instructions.<br></br>
(8.6.3) We or our data processors may transfer your personal data to third party service providers hired by us located outside Thailand. You acknowledge and consent to the transfer of your personal information to such third party service providers. We will process any such transfer. and/or complete processing of your personal data outside of Thailand in accordance with applicable personal data protection laws. And in this matter We will ensure that our third party service providers have data protection measures and policies that are no less stringent than the measures and policies we apply to your personal data.<br></br><br></br>
9. Indemnification<br></br>
(9.1) You agree to indemnify us, our employees, and content providers. Subcontractor Licensor and/or our representatives for any claims, liabilities, costs, claims, losses, damages, proceedings and/or expenses. that happened to us, that were caused by, related to or related to your use of your MVHub Plus account (whether by you or a third party) and/or your wrongdoing. or does not comply with any terms of the contract<br></br>
(9.2) This contract section 9 will continue to be effective after this contract ends.<br></br><br></br>
10. Termination of contract<br></br>
(10.1) We may stop providing services related to the website. (or any part of the website), MVHub Plus Services and/or any Content to you at any time by giving you at least 30 days' notice.<br></br>
(10.2) For serious misuse of the MVHub Plus services, we may suspend or terminate your membership plan and/or MVHub Plus account immediately without notice. or if you breach the contract We will normally give you an opportunity to make amends within 15 days of us notifying you of the breach. However, if you fail to make amends, We may cancel your membership plan. and/or terminate your access to your MVHub Plus account.<br></br>
(10.3) We may cancel your membership plan and/or suspend or cancel your MVHub Plus account, MVHub Plus Services and/or any Content. If you breach any other contract you have with us or any company in the PCCW group. and fail to correct it within the time period specified in those other contracts. If we suspend or terminate your MVHub Plus account, we will notify you of the actions you must take before we can reinstate your account. yours<br></br><br></br>
11. Effects of contract termination<br></br>
(11.1) Upon termination of your MVHub Plus Account, all licenses, rights and privileges granted to you under the Agreement will terminate. Any such cancellation This will not affect the enforceability of the Terms of Use. (This will apply as long as you are using and/or accessing any website. and regardless of whether you have an MVHub Plus account or not) and all rights or liabilities that exist of each party to this contract. and will not affect the entry into force or continued effect of any provision. contained in this contract which expressly or by implication is intended to survive or continue in force on or after such termination.<br></br><br></br>
12. Our other rights to suspend or terminate your MVHub Plus account.<br></br>
(12.1) We may limit, suspend, terminate or prohibit your access to your MVHub Plus account without prior notice. (with the condition that it is a case where it is reasonably practicable to do so) if<br></br>
(12.1.1) We are required by law to do this.<br></br>
(12.1.2) There is an urgent need that affects our ability to provide any Website, MVHub Plus Services and/or Content.<br></br>
(12.1.3) MVHub Plus services and/or any content has been withdrawn from distribution through the MVHub Plus service for any reason.<br></br>
(12.1.4) We do not hold any distribution rights with respect to any website, MVHub Plus Services, or any content on the MVHub Plus Services.<br></br>
(12.1.5) We have not obtained, held, maintained or extended any relevant legal authorization. or if any relevant legal permission is revoked or the relevant authority requires us to stop operating any website. Broadcasting of MVHub Plus services and/or content or<br></br>
(12.1.6) Any website, MVHub Plus services and/or content. including any advertising On any website, MVHub Plus services and/or content. Not in accordance with applicable standards any relevant laws or any requirements or orders issued from time to time by the relevant competent authority<br></br><br></br>
13. Unauthorized Access<br></br>
(13.1) Unauthorized access to MVHub Plus services and/or any content. It is a breach of contract. and violate the law Where certain content is restricted to registered users or certain registered users. You agree not to access such content by any means. That goes beyond providing us with your login details and password. You agree not to use any automated means. including, but not limited to, agents, robots, scripts, or spiders, to access, monitor, or copy any portion of the MVHub Plus Services, Content, or any website. except for automated methods that we have approved in advance in writing.<br></br><br></br>
14. Miscellaneous<br></br>
(14.1) You accept that this contract is your personal matter. You agree not to transfer your rights and obligations under the contract to any other person. or attempt to do so without our prior written consent. However, we may accept orders from persons who we reasonably believe are doing so with your permission.<br></br>
(14.2) When we need to contact you. We will use your email address and/or telephone number that you have given us to contact you. If you need to contact us Please use the mailing address, email address, and/or telephone number listed on the website. which we have provided to you for that purpose<br></br>
(14.3) Notices or communications that we send will be deemed to have been received by you immediately when the email is sent. If sent by email<br></br><br></br>
Updated on [01 December 2023]


        </Card>
      </Container>
    </>
  )
}
