import React from 'react'

export default function ResultFail() {

    return (
        <div className='bg-img'>
            <h1 className='pt-5 text-yellow text-center'>การชำระเงินล้มเหลว กรุณาลองใหม่อีกครั้ง</h1>
        </div>
    )
}
