import axios from 'axios'
import React, { useEffect, useState, useRef } from 'react'
import { useParams } from 'react-router-dom'
import {
    Button,
    Input,
    Container,
    Row,
    Col,
} from "reactstrap";


export default function Pay(props) {
    const { ref } = useParams()
    const [html, setHtml] = useState()
    const [refcardnum, setRefcardnum] = useState()
    const [refcardcvv, setRefcardcvv] = useState()
    const [refcardmm, setRefcardmm] = useState()
    const [refcardyy, setRefcardyy] = useState()
    const [value, setValue] = useState([])
    // const [vals, setVals] = useState([])
    // const formRef = useRef(null)
    const [IsLoading, setIsLoading] = useState(false)

    useEffect(() => {
        const params = new URLSearchParams()
        let ref_arr = String(ref).split('&')
        //console.log(ref_arr)
        setRefcardnum(ref_arr[1])
        setRefcardcvv(ref_arr[4])
        setRefcardmm(ref_arr[2])
        setRefcardyy(ref_arr[3])
        params.append('publicKey', process.env.REACT_APP_PK)
        params.append('gbpReferenceNo', ref_arr[0])
        axios.post(`${process.env.REACT_APP_GB}/v2/tokens/3d_secured`, params, {
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded'
            }
        }).then(result => {

            let url = String(result.data).split('<form')
            //console.log(url)
            let url2 = String(url[1]).split('</form>')
            let url3 = "<form"+url2[0]+"<button type='submit' class='btn btn-success btn-block'>ยืนยันชำระ</button></form>"
            setHtml(url3)


            //if (url != undefined) {
               //window.location.href = url_1_1_1_1_1 //url[1]?.split("'")[1]
            //}
            if (url === undefined) {
                window.location.href = `/package/result/fail`
            }
            // url.map(val => {
            //     setValue((prev) => {
            //         console.table(url)
            //         return [
            //             ...prev,
            //             val.split("'", 1)
            //         ]
            //     })
            // }
            // )
        }).finally(() => {
            // console.log(value)
            setIsLoading(true)
        })
    }, [])

    if (IsLoading == true) {
        return (
            <>
            <div style={container}>
            <Row style={formstyle}>
                <Col xs='12'>
                    <h3 className='text-center mb-2'>Credit/Debit</h3>
                </Col>
                <Col xs='12'>
                    <h5 className='text-center mb-2'>ยืนยันข้อมูลสำหรับการชำระเงิน</h5>
                </Col>
                <div className='warning-input' style={{ width: '100%' }}>
                    <Row>
                        <Col xs='10'>
                            <label>Card number</label>
                        </Col>
                        <Col xs='2'>
                            <label>CVV</label>
                        </Col>
                        <Col xs='10'>
                            <Input
                                name='cardNumber'
                                className='mb-2'
                                type='tel'
                                pattern='[0-9]{16}'
                                placeholder='Card Number'
                                fullwidth
                                readOnly
                                value={refcardnum}
                            />
                        </Col>
                        <Col xs='2'>
                            <Input
                                name='cvv'
                                className='mb-2'
                                type='text'
                                pattern='[0-9]{3}'
                                placeholder='CVV'
                                fullwidth
                                readOnly
                                value={refcardcvv}
                            />
                        </Col>
                        <Col xs='6'>
                            <label>mm</label>
                        </Col>
                        <Col xs='6'>
                            <label>yy</label>
                        </Col>
                        <Col xs='6'>
                            <Input
                                name='mm'
                                className='mb-2'
                                type='tel'
                                placeholder='mm'
                                pattern='[0-9]{2}'
                                readOnly
                                fullwidth
                                value={refcardmm}
                            />
                        </Col>
                        <Col xs='6'>
                            <Input
                                name='yy'
                                className='mb-2'
                                type='tel'
                                placeholder='yy'
                                pattern='[0-9]{2}'
                                readOnly
                                fullwidth
                                value={refcardyy}
                            />
                        </Col>
                        <Col xs='12'>
                <div dangerouslySetInnerHTML={{ __html: html, }}></div>
                        </Col>
                    </Row>
                    </div>
            </Row>
            </div>
            </>
        )
    } else {
        return <div className='bg-blank'>Loading</div>
    }
}

const container = {
    height: '900px',
}

const formstyle = {
    padding: '10%',
}