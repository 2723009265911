import React, { useState, useEffect } from "react";
import { Route, Switch } from 'react-router-dom';
import '../../assets/css/style.css'

import FooterBlack from "../../components/Footers/FooterBlack";
import SectionNavigation from "../index-sections/SectionNavigation.js";
import Body from "../tv-section/Body";

import loadingImg from '../../assets/img/body/loading.gif'
import axios from "axios";
import { getOS } from "getOs";
import Tv from "./Tv";

function TvPage() {
  const [datas, setDatas] = useState()
  const [isLoading, setIsLoading] = useState(true)
  useEffect(() => {
    if (!localStorage.getItem('auth')) {
      axios.post(`${process.env.REACT_APP_API_CONTENT2}/guest-live`, { device: getOS()[1] })
        .then(result => {
          setDatas(result)
          setIsLoading(false)
        })
    } else {
      axios.post(`${process.env.REACT_APP_API_CONTENT2}/live`, 'tv', {
        headers: {
          Authorization: `Bearer ${window.localStorage.getItem('auth')}`
        },
      }).then(result => {
        console.log(result)
        setDatas(result)
        setIsLoading(false)
      })
    }
  }, [])

  return (
    <>
      {isLoading === true &&
        <div className='bg-loading'>
          <img src={loadingImg} className='loading-img' alt='loading' />
        </div>
      }
      {isLoading === false &&
        <div className="bg-blank">
          <Switch>
            <Route path='/tv/:index/:name' component={() => <Tv />} />
            <Route path='/tv' exact>
              <SectionNavigation />
              <Body datas={datas} />
              <FooterBlack />
            </Route>
          </Switch>
        </div>
      }
    </>
  );
}

export default TvPage;
