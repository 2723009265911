import React from 'react'
import { BrowserRouter as Router } from "react-router-dom";
import { Switch, Route, Routes } from "react-router-dom";
import SectionNavigation from 'views/index-sections/SectionNavigation';
import TermTH from './TermTH';
import TermEN from './TermEN';
import ConditionsTH from './ConditionsTH';
import ConditionsEN from './ConditionsEN';
import CancelSubTH from './CancelSubTH';
import CancelSubEN from './CancelSubEN';
import DelAccTH from './DelAccTH';
import DelAccAndroid from './DelAccAndroid';
import Term from './Term';
import Privacy from './Privacy';

export default function PolicyPages() {
    return (
        <>
            <div className='bg-blank'>
                <SectionNavigation />
                <Router>
                    <Switch>
                    <Route path={'/policy/term-of-use'} render={() => <TermTH />} />
                    <Route path={'/policy/term-of-use-eng'} render={() => <TermEN />} />
                    <Route path={'/policy/term-and-conditions'} render={() => <ConditionsTH />} />
                    <Route path={'/policy/term-and-conditions-eng'} render={() => <ConditionsEN />} />
                    <Route path={'/policy/cancel-subscription'} render={() => <CancelSubTH />} />
                    <Route path={'/policy/cancel-subscription-eng'} render={() => <CancelSubEN />} />
                    <Route path={'/policy/delete-account'} render={() => <DelAccTH />} />
                    <Route path={'/policy/delete-account-android'} render={() => <DelAccAndroid />} />
                    <Route path={'/policy/term'} render={() => <Term />} />
                    <Route path={'/policy/privacy'} render={() => <Privacy />} />
                </Switch>
                </Router>
            </div>
        </>
    )
}
